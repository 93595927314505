.footer-container {
  background-color: #0f2a44;
  width: 100vw;
  /* height: 50vh; */
  display: flex;
  padding-bottom: 50px;
}
.footer-logo-container {
  width: 30vw;
  height: 100%;
}
.footer-logo {
  width: 20vw;
  height: auto;
  margin: 5vh 0 0 8vw;
}
.footer-dis {
  width: 20vw;
  height: fit-content;
  margin: 1vh 0 0 8vw;
  color: #fff;
  font-family: sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.footer-links-container {
  width: 20vw;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.footer-header {
  color: #fff;
  font-family: sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 4vw;
  /* margin: 1vh 0 3vh 2vw; */
}
.footer-link {
  color: #fff;
  font-family: sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin: 2vh 0 0 4vw;
  list-style: none;
  display: block;
  margin-bottom: 3px;
}

.footer-link a {
  margin: 0 10px;
  color: white;
  font-size: 24px;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-link a:hover {
  color: #ccc;
}

.footer-icon {
  vertical-align: middle;
}
.footer-text {
  margin-top: 2vh;
  margin-left: 4vw;
  width: 18vw;
  height: 5vh;
  flex-shrink: 0;
  border-radius: 10px;
  border: none;
}
.footer-button {
  width: 5vw;
  height: 4vh;
  color: #fff;
  font-family: sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  border-radius: 10px;
  background: #334862;
  border: none;
  margin-top: 2vh;
  margin-left: 4vw;
}
.big {
  margin: 0 2vw 0 0;
  font-size: 1.5vw;
}
.line {
  width: 0.1vh;
  border: none;
  height: 40vh;
  background-color: #fff;
  margin: 5vh 0;
}
.link {
  margin-top: 20px;
}

@media (max-width: 768px) {
  .footer-container {
    background-color: #0f2a44;
    width: 100vw;
    display: flex;
    flex-direction: column;
    height: fit-content;
  }

  .footer-logo {
    width: 250px;
    height: 100px;
  }

  .footer-dis {
    width: 80vw;
    height: -moz-fit-content;
    height: fit-content;
    margin: 0vh 10vw 0 10vw;
    color: #fff;
    font-family: sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }

  .line {
    width: 82vw;
    border: none;
    height: 0.1vw;
    background-color: #fff;
    margin: 3.5vh 9vw 0vh 9vw;
  }

  .footer-links-container {
    width: 80vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin: 0vh 10vw 0 10vw;
  }

  .footer-text {
    margin-top: 2vh;
    margin-left: 2vw;
    width: 56vw;
    height: 5vh;
    flex-shrink: 0;
    border-radius: 10px;
    border: none;
  }

  .footer-button {
    width: 20vw;
    height: 4vh;
    color: #fff;
    font-family: sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    border-radius: 10px;
    background: #334862;
    border: none;
    margin-top: 2vh;
    margin-left: 2vw;
  }

  .big {
    margin: 0vh 2vh 20vh 0vh;
    font-size: 10.5vw;
  }
}

.cat-card-img-footer {
  height: 5rem;
}

.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #fff;
  color: #0f2a44;
  padding: 10px;
  border-radius: 50%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: background-color 0.3s;
  z-index: 1000;
}

.back-to-top:hover {
  background-color: #dddddd;
}

.back-to-top-icon {
  font-size: 24px;
}
