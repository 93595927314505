.admin-orders-container {
  width: 80vw;
  min-height: 100vh;
  padding: 20px;
  background-color: #f8f9fa;
}

.admin-orders-search-container {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.admin-orders-search-container input {
  background-color: #e2e6fe;
  border: none;
  width: 300px;
  height: 40px;
  border-radius: 5px 0 0 5px;
  padding-left: 10px;
  outline: none;
  font-size: 16px;
}

.admin-orders-search-container button {
  background-color: #405089;
  border: none;
  height: 40px;
  padding: 0 10px;
  border-radius: 0 5px 5px 0;
  color: #fff;
  cursor: pointer;
}

.admin-orders-table-container {
  width: 100%;
  margin-top: 20px;
}

.admin-orders-table-container table {
  width: 100%;
  border-collapse: collapse;
}

.admin-orders-table-container th {
  background-color: #405089;
  color: #fff;
  padding: 10px;
  text-align: left;
}

.admin-orders-table-container td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.admin-change-status-button {
  margin-bottom: 20px;
  background-color: #405089;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
  min-width: 10rem;
}

/* Modal styling */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  width: 300px;
}

.modal-buttons {
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
}

.modal-confirm-button,
.modal-cancel-button {
  background-color: #405089;
  color: white;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 5px;
}

.modal-cancel-button {
  background-color: #ff5c5c;
}

.status-select {
  width: 100%;
  padding: 8px;
  margin-top: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.flexBtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.admin-orders-container {
  width: 80vw;
  min-height: 100vh;
  padding: 20px;
  background-color: #f8f9fa;
  margin: 0 auto;
  border-radius: 8px;
}

.admin-orders-search-container input {
  width: 300px;
  height: 40px;
  padding: 0 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.admin-orders-table-container {
  width: 100%;
  margin-top: 20px;
  border-radius: 8px;
  overflow: hidden;
}

.admin-orders-table-container table {
  width: 100%;
  border-collapse: collapse;
}

.admin-orders-table-container th,
.admin-orders-table-container td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.admin-sort-dropdown {
  margin-right: 50px;
  margin-bottom: 20px;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.admin-orders-table-container th {
  background-color: #405089;
  color: white;
}

tr {
  cursor: pointer;
}

tr:hover {
  background-color: #f1f1f1;
}
