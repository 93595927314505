.quote-container {
  width: 100vw;
  height: fit-content;
  margin-bottom: 5vh;
  margin-top: 5vh;
}

.quote-container table {
  margin: 0 auto 0 auto;
}
.quote-container th,
td {
  padding: 1vw;
  text-align: center;
}
.quote-container th {
  color: #000;
  font-family: sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-bottom: 0.1vh solid black;
}
.quote-container td {
  color: #626262;
  font-family: sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.table-data {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2vw;
}
.table-data img {
  width: 3.5vw;
}
.total-table {
  margin: 0 0 0 10vw;
  width: 80vw;
  display: flex;
  align-items: end;
  justify-content: end;
}
.quote-header {
  color: #11314c;
  font-family: sans-serif;
  font-size: 4vh;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
  margin-top: 7vh;
}
.quote-form-container {
  width: 100vw;
  height: fit-content;
}
.quote-form-container table {
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  width: fit-content;
}
.quote-form-container td {
  text-align: left;
  width: 11vw;
  color: #626262;
  font-family: sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 1vh 1vw 0 1vw;
}
.quote-label-border {
  border-bottom: 0.1vh solid black;
}
.quote-container input {
  border: 1px solid #c6c6c6;
  background: #fff;
  width: 40vw;
  height: 4vh;
}
.quote-container textarea {
  border: 1px solid #c6c6c6;
  background: #fff;
  width: 40vw;
  height: 8vh;
  resize: none;
}
.quote-button {
  width: 12vw;
  margin: 5vh 0 0 34vw;
}
.quote-btn {
  font-size: 20px;
  font-weight: 600;
  margin: 5vh 0 0 34vw;
  padding: 5px 4rem;
  background-color: #eba9d7;
  color: #fff;
  border: none;
  border-radius: 100px;
}
@media (max-width: 768px) {
  .quote-container th {
    color: #000;
    font-family: sans-serif;
    font-size: 1vh;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    border-bottom: 0.1vh solid black;
  }
  .quote-container td {
    color: #626262;
    font-family: sans-serif;
    font-size: 1vh;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }
  .quote-container table {
    margin: 0;
  }
  .quote-header {
    color: #11314c;
    font-family: sans-serif;
    font-size: 2vh;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: center;
    margin-top: 3vh;
  }

  .quote-form-container table {
    margin-left: 18vw;
  }
  .quote-button {
    width: 27vw;
    margin: 5vh 0 0 34vw;
  }
}
