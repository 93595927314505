.product-page-container {
  width: 100vw;
  height: fit-content;
}
.product-page-upper-container {
  width: 100vw;
  height: fit-content;
  display: flex;
  min-height: 83vh;
  /* background-color: red; */
}
.product-page-upper-container-img-small {
  display: flex;
}
.product-page-upper-container-img-small img {
  height: 1vw;
}
.product-page-upper-container-img {
  /* background-color: yellow; */
  width: 50vw;
  display: flex;
}
.product-page-lower-container {
  width: 100vw;
  /* min-height: 83vh; */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  /* background-color: red; */
  color: #000;
  font-family: sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.product-page-lower-container li {
  color: #626262;
  font-family: sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.product-page-upper-container img {
  width: 30vw;
  margin-left: 20vw;
}
.product-page-dis-container {
  width: 50vw;
  height: fit-content;
}

.product-page-dis-container p {
  color: #626262;
  font-family: sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 3vh 0 0 2vw;
  width: 40vw;
}
.product-page-dis-container h1 {
  color: #11314c;
  font-family: sans-serif;
  font-size: 64px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 1vh 0 0 2vw;
}
.product-page-dis-container h2 {
  color: #000;
  font-family: sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 1vh 0 0 2vw;
}
.product-page-dis-container hr {
  width: 5vw;
  text-align: left;
  border: none;
  height: 0.3vh;
  background-color: #11314c;
  margin: 1vh 0 0 2vw;
}
.product-page-dis-container table {
  margin: 5vh 0 0 2vw;
}
.product-page-dis-container th {
  text-align: left;
  padding: 0 0 1vh 0;
  width: 15vw;
  border-bottom: 0.3vh solid #11314c;
}
.product-page-dis-container td {
  text-align: right;
  padding: 0 0 1vh 0;
  width: 15vw;
  border-bottom: 0.3vh solid #11314c;
}
.quantity {
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 0;
  margin: 2vh 0 0 2vw;
}
.quantity__minus,
.quantity__plus {
  display: block;
  width: 2.5vw;
  height: 3vh;
  margin: 0;
  background: #73a9fa;
  text-decoration: none;
  text-align: center;
  line-height: 23px;
}
.quantity__minus:hover,
.quantity__plus:hover {
  background: #73a9fa;
  color: #73a9fa;
}
.quantity__minus {
  border-radius: 15px 0 0 15px;
}
.quantity__plus {
  border-radius: 0 15px 15px 0;
}
.quantity__input {
  width: 2vw;
  height: 2.5vh;
  margin: 0;
  padding: 0;
  text-align: center;
  border-top: 2px solid #73a9fa;
  border-bottom: 2px solid #73a9fa;
  border-left: 1px solid #73a9fa;
  border-right: 2px solid #73a9fa;
  background: #fff;
  color: #000;
}
.quantity__minus:link,
.quantity__plus:link {
  color: #000;
}
.quantity__minus:visited,
.quantity__plus:visited {
  color: #fff;
}

.p-button {
  color: #000;
  margin: 3vh 0 0 1vw;
}

.product-buy-now-button {
  display: flex;
  padding: 1vh 0 0 2vw;
  gap: 2vw;
}

@media (max-width: 768px) {
  .product-page-upper-container {
    width: 100vw;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .product-page-upper-container img {
    width: 80vw;
    margin-left: 5vw;
  }
  .product-page-dis-container {
    width: 90vw;
    height: fit-content;
  }
  .product-page-dis-container p {
    color: #626262;
    font-family: sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 3vh 0 0 2vw;
    width: 90vw;
  }
  .product-page-dis-container h1 {
    color: #11314c;
    font-family: sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 1vh 0 0 2vw;
  }
  .product-page-dis-container h2 {
    color: #000;
    font-family: sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 1vh 0 0 2vw;
  }
  .product-page-dis-container hr {
    width: 15vw;
    text-align: left;
    border: none;
    height: 0.3vh;
    background-color: #11314c;
    margin: 1vh 0 0 2vw;
  }
  .product-page-dis-container table {
    margin: 5vh 0 0 2vw;
  }
  .product-page-dis-container th {
    text-align: left;
    padding: 0 0 1vh 0;
    width: 80vw;
    border-bottom: 0.3vh solid #11314c;
  }
  .product-page-dis-container td {
    text-align: right;
    padding: 0 0 1vh 0;
    width: 15vw;
    border-bottom: 0.3vh solid #11314c;
  }
  .quantity {
    display: flex;
    align-items: center;
    justify-content: left;
    padding: 0;
    margin: 2vh 0 0 2vw;
  }
  .quantity__minus,
  .quantity__plus {
    display: block;
    width: 10vw;
    height: 5vh;
    margin: 0;
    background: #73a9fa;
    text-decoration: none;
    text-align: center;
    line-height: 23px;
  }
  .quantity__minus:hover,
  .quantity__plus:hover {
    background: #73a9fa;
    color: #73a9fa;
  }
  .quantity__minus {
    border-radius: 15px 0 0 15px;
  }
  .quantity__plus {
    border-radius: 0 15px 15px 0;
  }
  .quantity__input {
    width: 8vw;
    height: 4.5vh;
    margin: 0;
    padding: 0;
    text-align: center;
    border-top: 2px solid #73a9fa;
    border-bottom: 2px solid #73a9fa;
    border-left: 1px solid #73a9fa;
    border-right: 2px solid #73a9fa;
    background: #fff;
    color: #000;
  }
  .p-button {
    color: #000;
    margin: 3vh 0 0 1vw;
  }
  .product-buy-now-button {
    display: flex;
    padding: 2vh 0 0 2vw;
    gap: 2vw;
  }
}
