.nav-logo-container {
  width: 100vw;
  height: 10vh;
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-logo-container img {
  height: 100%;
}

.hamburger {
  display: none;
  cursor: pointer;
}

.hamburger i {
  font-size: 24px;
}

.quote-button-container {
  position: absolute;
  top: 50%;
  right: 20px; /* Adjust as needed */
  transform: translateY(-50%);
  display: flex;
  align-items: center;
}

.quote-button-container .cart-count {
  margin-left: 8px; /* Adjust as needed */
}

.navbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background: #fff;
  box-shadow: 0px 20px 20px -20px rgba(0, 0, 0, 0.25);
}

.navbar .menu {
  display: flex;
  flex: 1;
  justify-content: space-around;
  align-items: center;
}

.navbar .menu a {
  color: white;
  text-decoration: none;
  color: #575757;
  font-family: sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.navbar .menu a:hover {
  color: black;
}

.navbar .search-bar {
  display: flex;
  flex: 0.8;
  justify-content: space-between;
  border-radius: 41px;
  align-items: center;
  padding: 0.3vw 1vw;
}

.navbar .search-bar input {
  width: 100%;
  max-width: 400px;
  padding: 5px 10px;
  border: none;
  border-radius: 5px 0 0 5px;
  outline: none;
}

.navbar .search-bar svg {
  cursor: pointer;
}

.navbar .search-bar button {
  padding: 5px 10px;
  background-color: #575757;
  color: white;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
}

.navbar .search-bar button:hover {
  background-color: #757575;
}

.space {
  margin: 0 0.2vw;
}

.carts-container {
  position: relative;
  display: inline-block;
}

.cart-icon {
  font-size: 24px;
  color: #333;
}

.cart-count {
  position: absolute;
  top: -10px;
  right: -10px;
  background: red;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 12px;
  font-weight: bold;
}

.quote-button-container {
  display: none;
}
.quote-button-dis {
  display: block;
}
.logo {
  width: 12rem;
  cursor: pointer;
}
.icon {
  margin-left: 20px;
}
@media (max-width: 768px) {
  .nav-logo-container {
    width: 100vw;
    height: 10vh;
    text-align: center;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .quote-button-container {
    display: block;
    right: 2px;
  }
  .quote-button-dis {
    display: none;
  }

  .hamburger {
    display: block;
  }

  .hamburger {
    cursor: pointer;
  }

  .icon-size {
    height: fit-content;
    font-size: 6vh;
    padding: 2vh;
  }

  .navbar {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 0;
    display: none;
  }

  .navbar.open {
    display: flex;
    gap: 5px;
  }
  .icon {
    margin-left: 10px;
    margin-top: 10px;
  }
  .navbar .menu {
    flex-direction: column;
    align-items: flex-start;
    margin: 1vh 0;
  }

  .navbar .search-bar {
    width: 100%;
    justify-content: center;
    margin-bottom: 10px;
  }

  .navbar .menu a {
    color: white;
    text-decoration: none;
    color: #000;
    font-family: sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 2vw;
    text-align: left;
  }
}
.flex {
  display: flex;
  align-items: center;
}

@media (max-width: 450px) {
  .logo {
    width: 10rem;
  }
  .button-container {
    padding: 10px;
  }
}
