.cat-b2c-card {
  background-color: #fff;
  padding: 16px;
  margin: 12px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.cat-b2c-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}

.cat-b2c-img-container {
  position: relative;
  margin-bottom: 16px;
}

.cat-b2c-card-img {
  width: 100%;
  cursor: pointer;
  transition: transform 0.3s ease;
  height: 10rem;
  object-fit: contain;
}

.cat-b2c-button {
  background-color: rgb(209, 228, 255);
  border: none;
  border-radius: 50px;
  color: #fff;
  padding: 12px 20px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
  margin-top: -70px;
  z-index: 999;
  max-width: 12rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}

.cat-b2c-button:hover {
  background-color: rgb(189, 208, 235);
}

.cat-b2c-title-container {
  text-align: left;
}

.cat-b2c-pro-sub-title {
  font-size: 12px;
  font-weight: 600;
  margin: 2px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 400;
}
.cat-b2c {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
}
.cat-b2c-pro-title {
  font-size: 14px;
  color: #666;
  margin: 4px 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 18px;
  font-weight: 500;
  color: #333;
}
.cat-b2c-pro-price {
  font-size: 18px;
  font-weight: 700;
}
