.baner-container {
  width: 100%;
  margin: 0 auto;
}

.baner-slide {
  position: relative;
  height: 22rem; /* Set the height of the slide container */
}

.baner-image {
  width: 100%;
  height: 100%; /* Make the image occupy the full height of the container */
  object-fit: cover;
  max-height: 22rem;
}

.baner-content {
  position: absolute;
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Offset to center the content */
  color: white;
  text-align: center; /* Center text horizontally */
  width: 100%; /* Ensure content takes full width */
  padding: 0 20px; /* Optional: Add padding for spacing */
}

.baner-title {
  font-size: 36px; /* Larger font size */
  font-weight: bold;
}

.baner-subtitle {
  font-size: 24px; /* Larger font size */
  margin-top: 10px; /* Space between title and subtitle */
}

.shimmer {
  min-height: 350px;
  width: 80%;
  border-radius: 8px;
  background: linear-gradient(
      90deg,
      #f0f0f0 25%,
      #e0e0e0 50%,
      #f0f0f0 75%
  );
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
}

@keyframes shimmer {
  0% {
      background-position: -200% 0;
  }
  100% {
      background-position: 200% 0;
  }
}
