/* General Container Styling */
.product-list-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  /* background-color: #f9f9f9; */
  border-radius: 8px;
  /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); */
}

/* Title Styling */
.product-list-title {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}
/* Category List Styling */
.product-list-cat-list-container {
  float: left;
  width: 20%;
  padding-right: 20px;
  border-right: 1px solid #ddd;
}

.product-list-cat-list-container h1 {
  font-size: 1.5rem;
  margin-bottom: 15px;
  color: #333;
}

.product-list-cat-list-container p {
  font-size: 1rem;
  margin: 10px 0;
  padding: 10px;
  cursor: pointer;
  color: #333;
  /* background-color: #f0f0f0; */
  border-radius: 4px;
  /* transition: background-color 0.3s ease; */
}

.product-list-cat-list-container p:hover {
  background-color: #eba9d7;
  color: #fff;
}

/* Product Listing Styling */
.product-list-cat-container {
  float: right;
  width: 75%;
}

.product-list-selection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.product-list-selection select {
  padding: 8px 12px;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.product-list-selection p {
  font-size: 1rem;
  color: #666;
}

/* Product Card Container */
.product-list-card-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

/* Pagination Styling */
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination button {
  padding: 10px 15px;
  margin: 0 5px;
  font-size: 1rem;
  border: 1px solid #ddd;
  background-color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.pagination button:hover {
  background-color: #eba9d7;
  color: #fff;
}

/* Product Catalogue Card */
.product-list-card-container .product-catalogue-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  transition: transform 0.3s ease;
}

.product-list-card-container .product-catalogue-card:hover {
  transform: translateY(-5px);
}

/* Clearing Floats */
.product-list-showcase::after {
  content: "";
  display: table;
  clear: both;
}

@media (max-width: 768px) {
  .product-list-cat-list-container {
    width: 25%;
  }
  .product-list-container {
    padding: 5px;
  }
  .product-list-cat-container {
    float: right;
    width: 65%;
  }
  .product-list-cat-list-container h1 {
    font-size: 15px;
  }
  .product-list-cat-list-container p {
    font-size: 13px;
  }
  .product-list-selection p {
    font-size: 0.8rem;
    color: #666;
  }
  .product-list-selection select {
    padding: 4px 8px;
  }
}
.loader-b2b {
  width: 50vw;
  height: 20vh;
  justify-content: center;
  align-items: center;
  display: flex;
}
