.lat-arr-card-container {
  width: 20vw;
  height: 30vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  margin: 0.5vh 0.5vw 0.5vh 0.5vw;
}

.lat-arr-img-container {
  position: relative;
  height: 20vh;
  width: 20vw;
}

.lat-arr-img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.lat-arr-button {
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 0 20px 0 0;
  background-color: #eba9d7;
  color: #fff;
  padding: 0.5vw 1vw;
  font-family: sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border: none;
  cursor: pointer;
}

.lat-arr-title,
.lat-arr-sub-title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0.5vh 1vw;
  box-sizing: border-box;
  text-align: center;
}

.lat-arr-title {
  color: #000;
  font-family: sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}

.lat-arr-sub-title {
  color: #000;
  font-family: sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

@media (max-width: 768px) {
  .lat-arr-card-container {
    width: 90vw;
    height: auto;
  }

  .lat-arr-img-container {
    height: 50vw;
    width: 90vw;
    text-align: center;
  }
  .lat-arr-img {
    height: 50vw;
    object-fit: cover;
    width: auto;
  }

  .lat-arr-title,
  .lat-arr-sub-title {
    font-size: 14px;
    padding: 1vh 2vw;
  }

  .lat-arr-button {
    position: absolute;
    bottom: 0;
    left: 0;
    border-radius: 0 20px 0 0;
    background-color: #eba9d7;
    color: #fff;
    padding: 1vh 8vw;
    font-family: sans-serif;
    font-size: 2vh;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border: none;
    cursor: pointer;
  }
}
