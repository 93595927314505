.custom-carousel-container {
    width: 100%;
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    margin-bottom: 100px;
  }
  
  .carousel-track {
    display: flex;
    transition: transform 0.8s ease-in-out;
  }
  
  .carousel-item {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }
  
  .carousel-item img {
    height: 80px; 
    object-fit: contain; 
    border-radius: 8px;
    transition: transform 0.3s ease; 
  }
  
  .carousel-item img:hover {
    transform: scale(1.05);
  }
  
  @media (max-width: 767px) {
    .carousel-item img {
      width: 200px;
      height: 200px;
    }
    .carousel-item{
        padding: 0px;
    }
  }
  