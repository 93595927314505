.get-in-touch-container-c {
  width: 100vw;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.get-in-touch-container-c h1 {
  color: #11314c;
  font-family: sans-serif;
  font-size: 64px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0;
}
.get-in-touch-container-p {
  margin-top: 0;
  width: 15vw;
  color: #000;
  text-align: center;
  font-family: sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

@media (max-width: 768px) {
  .get-in-touch-container h1 {
    color: #11314c;
    font-family: sans-serif;
    font-size: 5vh;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 0;
  }
  .get-in-touch-container-c h1 {
    font-size: 30px;
  }
  .get-in-touch-container-p {
    margin-top: 0;
    width: 50vw;
    color: #000;
    text-align: center;
    font-family: sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}
