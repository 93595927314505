body {
  font-family: sans-serif;
}

.order-details-container {
  width: 85%;
  margin: 0 auto;
  padding: 40px;
  border-radius: 15px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  font-family: sans-serif;
}

.heading {
  font-size: 25px;
  font-weight: 600;
  color: #333;
  margin-bottom: 30px;
}

.order-info {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}

.order-customer-info,
.order-products {
  flex: 1;
  background-color: #fff;
  padding: 25px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.03);
}

.subheading {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 20px;
  color: #444;
}

.info-item {
  font-size: 20px;
  margin: 30px 0;
  color: #555;
}

.info-label {
  font-weight: 600;
  color: #333;
  font-size: 20px;
  margin-right: 10px;
}

.product-item {
  padding: 15px;
  border: 1px solid #eee;
  border-radius: 8px;
  margin-bottom: 15px;
  background-color: #f4f4f4;
}

.editable-fields {
  display: flex;
  gap: 30px;
  align-items: center;
  margin-top: 30px;
}

.tracking-status-container,
.tracking-number-container {
  display: flex;
  align-items: center;
  gap: 15px;
}

.status-select {
  padding: 10px;
  border-radius: 6px;
  border: 1px solid #ddd;
  width: 220px;
  background-color: #fff;
  margin-top: 0px;
}

.tracking-number-input {
  padding: 10px;
  border-radius: 6px;
  border: 1px solid #ddd;
  width: 260px;
}

.save-tracking-button {
  background-color: #007bff;
  color: #fff;
  padding: 12px 24px;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.save-tracking-button:hover {
  background-color: #0056b3;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0;
}
