.product-cate-card {
  width: 300px;
  height: 250px;
  border-radius: 15px;
  background-color: #fff;
  box-shadow: 0px 3px 6px 2px rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-right: 30px;
  margin-bottom: 30px;
}
.product-cate-card:hover {
  background-color: whitesmoke;
  cursor: pointer;
}
.product-cate-img-container {
  width: 280px;
  height: 250px;
  margin-top: 8px;
  margin-left: 8px;
  margin-right: 8px;
  object-fit: cover;
}
.product-cate-img {
  width: 100%;
  border-radius: 15px;
  height: 180px;
  object-fit: cover;
}
.product-cate-title {
  /* width: 20vw; */
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
}

@media (max-width: 768px) {
  .product-cate-card {
    width: 90vw;
    height: 35vh;
    border-radius: 22px;
    background-color: #fff;
    box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-right: 0px;
  }

  .product-cate-img-container {
    width: 88vw;
    height: 28vh;
  }
  .product-cate-img {
    height: 88%;
    border-radius: 22px;
  }
  .product-cate-title {
    width: 90vw;
    height: 5vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
