/* Container */
.upload-post-container {
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  padding-left: 50px;
  padding-right: 50px;
  border-radius: 8px;
}

/* Heading */
.upload-post-heading {
  text-align: center;
  margin-bottom: 30px;
  font-size: 28px;
  font-weight: bold;
  color: #333;
}

/* Form Content */
.upload-post-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Form Styling */
.upload-post-form {
  width: 90%;
  background: #ffffff;
  padding: 20px;
  border-radius: 10px;
  margin: 0 auto;
}

.upload-post-button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
}

.upload-post-input {
  width: calc(100% - 20px);
  padding: 12px;
  margin: 15px 0;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-size: 16px;
}

.upload-post-file-label {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 2px dashed #007bff;
  border-radius: 6px;
  padding: 15px;
  margin: 15px 0;
  background-color: #f0f8ff;
  transition: background-color 0.3s ease;
}

.upload-post-file-label:hover {
  background-color: #e0f0ff;
}

.upload-post-file-input {
  display: none; /* Hide the default file input */
}

.upload-post-file-icon {
  margin-right: 10px;
  color: #007bff;
}

.upload-post-file-text {
  font-size: 16px;
  font-weight: 500;
  color: #333;
}

/* Image Preview */
.image-preview-container {
  margin: 15px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-preview {
  max-width: 100%;
  max-height: 150px;
  border-radius: 6px;
  object-fit: cover;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Upload Button */
.upload-post-button {
  padding: 12px;
  background-color: #007bff;
  color: #ffffff;
  border: none;
  border-radius: 6px;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.upload-post-button:hover {
  background-color: #0056b3;
}

/* Banner List */
.banner-list {
  margin-top: 40px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

/* Banner Item */
.banner-item {
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 15px;
  text-align: center;
  transition: transform 0.3s ease;
}

.banner-item:hover {
  transform: translateY(-5px);
}

.banner-image {
  width: 100%;
  height: auto;
  border-radius: 6px;
  margin-bottom: 15px;
}

.banner-item h2 {
  font-size: 20px;
  font-weight: 600;
  color: #333;
  margin-bottom: 10px;
}

.banner-item p {
  font-size: 16px;
  color: #555;
  margin-bottom: 15px;
}

/* Delete Button */
.delete-button {
  display: inline-flex;
  align-items: center;
  padding: 8px 12px;
  background-color: #ff4d4d;
  color: #ffffff;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.delete-button:hover {
  background-color: #e60000;
}

.delete-button svg {
  margin-right: 5px;
}

.edit-button {
  display: inline-flex;
  align-items: center;
  padding: 8px 12px;
  background-color: #0056b3;
  color: #ffffff;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-right: 10px;
}
.edit-button svg {
  margin-right: 5px;
}
.upload-post-container {
  width: 100%;
}

.upload-post-heading {
  text-align: center;
  margin-bottom: 20px;
}

.add-graphics-button {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.add-graphics-button:hover {
  background-color: #0056b3;
}

.banner-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.banner-item {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.banner-image {
  max-width: 100%;
  max-height: 200px;
  border-radius: 4px;
  margin-bottom: 10px;
}

.delete-button {
  background-color: #dc3545;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.delete-button:hover {
  background-color: #c82333;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 60%;
  max-width: 500px;
  text-align: center;
}

.modal-close-button {
  padding: 12px;
  background-color: #6c757d;
  color: #ffffff;
  border: none;
  border-radius: 6px;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.modal-close-button:hover {
  background-color: #5a6268;
}
.banner-table-container {
  margin-top: 20px;
  overflow-x: auto;
}

.banner-table {
  width: 100%;
  border-collapse: collapse;
  background-color: #ffffff;
}

.banner-table th,
.banner-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.banner-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.banner-table td {
  vertical-align: middle;
}

.banner-table-image {
  width: 100px;
  height: auto;
  border-radius: 6px;
}

.delete-button {
  background-color: #dc3545;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.delete-button:hover {
  background-color: #c82333;
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');

body, .upload-post-container {
  font-family: 'Inter', sans-serif;
}

.upload-post-container {
  max-width: 100%;
  padding: 16px;
}

.upload-post-input, .color-picker {
  width: 100%;
  margin-top: 8px;
}

.modal-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}
