/* Container and Layout */
.container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #fff;
    padding: 20px;
    padding-top: 0;
  }
  
  /* Content */
  .policyContent {
    max-width: 1000px;
    margin: 0 auto;
    background-color: white;
    padding: 50px;
    padding-top: 0;
    border-radius: 10px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    margin-top: 40px;
    margin-bottom: 40px;
  }
  
  .title {
    font-size: 40px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
    color: #1a202c;
  }
  
  .description {
    font-size: 18px;
    color: #4a5568;
    margin-bottom: 30px;
    line-height: 1.8;
    text-align: justify;
  }
  
  .subtitle {
    font-size: 26px;
    font-weight: 600;
    color: #2d3748;
    margin-bottom: 15px;
  }
  
  .text {
    font-size: 18px;
    color: #4a5568;
    margin-bottom: 20px;
    line-height: 1.6;
    text-align: justify;
  }
  
  .list {
    font-size: 18px;
    color: #4a5568;
    margin-bottom: 20px;
    line-height: 1.8;
    list-style-type: disc;
    margin-left: 20px;
  }
  
  .email {
    color: #3182ce;
    text-decoration: none;
  }
  
  .email:hover {
    color: #2b6cb0;
    text-decoration: underline;
  }
  