.cat-card {
  width: 16rem;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  padding: 1rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  overflow: hidden;
  border-radius: 8px;
}

.cat-card:hover {
  background-color: whitesmoke;
}

.cat-card-img {
  width: 100%;
  height: auto;
  max-height: 50%;
  object-fit: contain;
  cursor: pointer;
  border-radius: 8px;
}

.cat-pro-sub-title {
  color: #000;
  font-family: sans-serif;
  font-size: 16px;
  font-weight: 300;
  /* text-align: center; */
}

.cat-pro-title {
  color: #000;
  font-family: sans-serif;
  font-size: 14px;
  font-weight: 400;
  text-align: justify;
  overflow: hidden;
  margin-right: 10px;
}

.cat-button {
  position: absolute;
  bottom: 10px;
  border-radius: 41px;
  background: #eba9d7;
  border: none;
  color: #fff;
  font-family: sans-serif;
  font-size: 15px;
  font-weight: 700;
  padding: 10px 15px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cat-button:hover {
  background-color: #d47cb2;
}

.cat-title-container {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  width: 100%;
  padding-top: 0.5rem;
  padding-bottom: 2rem;
}

.cat-price {
  font-weight: bold;
  color: #000;
  font-size: 16px;
}

@media (max-width: 768px) {
  .cat-card {
    width: auto;
    height: auto; /* Let it expand naturally on smaller screens */
  }

  .cat-title-container {
    width: 60vw;
    flex-direction: column;
    align-items: center;
  }

  .cat-card-img {
    width: 100%;
  }

  .cat-button {
    bottom: 10px;
    padding: 12px 24px;
    font-size: 14px;
  }

  .cat-pro-title {
    font-size: 14px;
  }

  .cat-pro-sub-title {
    font-size: 16px;
  }
}
.carousel {
  position: relative;
  overflow: hidden; /* Hide overflow to keep carousel neat */
  width: 100%; /* Adjust as necessary */
  height: 100%; /* Ensure the height adjusts based on image */
}
.carousel img {
  width: 100%; /* Adjust as necessary */
  height: 100%;
}
.cat-card-img {
  width: 100%; /* Full width for the image */
  height: 50%; /* Maintain aspect ratio */
  transition: opacity 0.5s ease-in-out; /* Smooth transition */
}

/* Additional styling for the card */
.cat-card {
  /* Your existing styles */
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  background-color: #fff;
  text-align: center; /* Center text and images */
}
