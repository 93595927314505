.add-company-container {
  width: 40vw;
  margin: 0 1rem;
  padding: 2rem;
  background-color: #fff;
  border-radius: 10px;
}

.add-company-container h2 {
  text-align: center;
  margin-bottom: 2rem;
}

.add-company-container label {
  margin: 1rem 0 1rem 0;
  font-size: 1.1rem;
  color: #333;
  margin-bottom: 2rem;
}

.add-company-container input {
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 1.5rem;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 1rem;
  margin-top: 0.3rem;
  margin-right: 20rem;
}

.gst-number-row {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  justify-content: space-between;
}

.gst-number-row input {
  flex: 1;
  padding: 0.75rem;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin: 0rem;
  margin-right: 1rem;
}

.icon {
  font-size: 1.5rem;
  margin-left: 0.5rem;
  cursor: pointer;
  color: #007bff;
}

.add-company-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}

.add-company-buttons button {
  width: 45%;
  padding: 0.75rem;
  border-radius: 25px;
  font-size: 1.1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.save {
  background-color: #28a745;
  color: white;
  border: none;
}

.save:hover {
  background-color: #218838;
}

.cancel {
  background-color: #dc3545;
  color: white;
  border: none;
}

.cancel:hover {
  background-color: #c82333;
}

.error {
  color: red;
  font-size: 0.9rem;
  display: block;
  margin-bottom: 1.5rem;
  margin-top: -0.5rem;
}
