.cart-container{
    width: 100vw;
    height: fit-content;
    min-height: 83vh;
    margin-bottom: 5vh;
}
.cart-heading{
    margin:  5vh 0 5vh 10vw;
    color: #11314C;
    font-family: sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.cart-container table{
    margin:  0 0 0 10vw;
}
.cart-container th,td{
    padding: 1vw;
    text-align: center;
}
.cart-container th{
    color: #000;
    font-family: sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-bottom: 0.1vh solid black;
}
.cart-container td{
    color: #626262;
    font-family: sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.table-data{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2vw;
}
.table-data img{
    width: 2.5vw;
}
.cart-line{
    width: 80vw;
    height: 0.1vh;
    border: none;
    background-color: #000;
}
.total-table{
    margin:  0 0 0 10vw;
    width: 80vw;
    display: flex;
    align-items: end;
    justify-content: end;
}
.cart-button{
    width: 20vw;
    margin: 6vh 0 0 70vw;
}

@media (max-width: 768px) {
    .cart-container th{
        color: #000;
        font-family: sans-serif;
        font-size: 0.7vh;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        border-bottom: 0.1vh solid black;
    }
    .cart-container td{
        color: #626262;
        font-family: sans-serif;
        font-size: 0.6vh;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
    }

    .cart-button{
        width: 80vw;
        margin: 6vh 0 0 10vw;
    }

}