.latest-arrival-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 1vh 0 1vh 0;
  margin-top: 5rem;
  margin-bottom: 2rem;
}
.latest-arrival-title {
  color: #11314c;
  font-family: sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 1vh;
}
.latest-arrival-card-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0.1vw;
  height: fit-content;
}

@media (max-width: 768px) {
  .latest-arrival-card-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 0.1vw;
    height: fit-content;
  }
}

.shimmerLatestArrival {
  min-height: 350px;
  width: 100vw;
  border-radius: 8px;
  background: linear-gradient(
      90deg,
      #f0f0f0 25%,
      #e0e0e0 50%,
      #f0f0f0 75%
  );
  animation: shimmer 1.5s infinite;
  margin: auto;
  margin-top: 50px;
}