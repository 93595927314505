/* AdminNewsLetter.css */
.newsletter-container {
  padding: 20px;
  font-family: Arial, sans-serif;
}

.newsletter-container h1 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

.newsletter-container ul {
  list-style-type: none;
  padding: 0;
}

.newsletter-container li {
  background-color: #f4f4f4;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.newsletter-container p {
  margin: 0;
  font-size: 16px;
  color: #555;
}

.loading-message,
.error-message,
.no-newsletter-message {
  text-align: center;
  font-size: 18px;
  color: #666;
  margin-top: 20px;
}

.loading-message {
  color: #40508d;
}

.error-message {
  color: #dc3545;
}

.no-newsletter-message {
  color: #28a745;
}

.export-button {
  display: block;
  margin: 20px auto;
  padding: 12px 20px;
  background-color: #40508d;
  color: #fff;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.export-button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

.loading-message,
.error-message,
.no-newsletter-message {
  text-align: center;
  font-size: 18px;
  color: #666;
  margin-top: 20px;
  padding: 10px;
  border-radius: 5px;
}

.loading-message {
  color: #007bff;
}

.error-message {
  color: #dc3545;
}

.no-newsletter-message {
  color: #28a745;
}

@media (max-width: 600px) {
  .export-button {
    font-size: 14px;
    padding: 10px 15px;
  }
}

