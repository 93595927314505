/* QuotesTable.css */
.quotes-table-container {
  margin: 20px auto;
  width: 70%;
}

.quotes-table {
  width: 100%;
  border-collapse: collapse;
}

.quotes-table th,
.quotes-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  text-align: center;
}

.quotes-table th {
  background-color: #f2f2f2;
  color: #333;
  font-weight: bold;
}

.quotes-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.quotes-table tr:hover {
  background-color: #f1f1f1;
}

.quotes-table th,
.quotes-table td {
  padding: 12px 15px;
}

.quotes-table td {
  word-wrap: break-word;
  max-width: 200px;
}

/* AdminQuote.css */

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  max-width: 800px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.products-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.products-table th,
.products-table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.view-button {
  background-color: #00704a;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
}

.view-button:hover {
  background-color: #005a39;
}
