.admin-products-container {
  width: 80vw;
  min-height: 100vh;
}

.admin-product-header {
  width: 80vw;
  height: 15vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.admin-product-header-left {
  width: 40vw;
  height: 15vh;
  display: flex;
  align-items: center;
}
.admin-product-header-left p {
  margin: 0 0 0 8vw;
}
.admin-product-header-left button {
  margin: 0 0 0 1vw;
  width: 10vw;
  height: 5vh;
  border: none;
  border-radius: 15px;
  background-color: #dee2fa;
}
.admin-product-header-left button:hover {
  background-color: #c6ccf3;
}
.admin-product-header-right {
  width: 40vw;
  height: 15vh;
  display: flex;
  align-items: center;
}

.admin-product-header-right input {
  background-color: #e2e6fe;
  border: none;
  width: 10vw;
  height: 5vh;
  border-radius: 15px 0 0 15px;
  padding: 0 0 0 1vw;
  outline: none;
  margin: 0 0 0 20vw;
}
.admin-product-header-right button {
  background-color: #e2e6fe;
  border: none;
  /* width: 3vw; */
  height: 5vh;
  padding: 0 1vw 0 0;
  border-radius: 0 15px 15px 0;
  outline: none;
}
/*=====================admin product table=================*/

.admin-product-table-container {
  width: 75vw;
  min-height: 48vh;
  height: fit-content;
  padding: 1vh 0 1vh 3vw;
}

.admin-product-table-container table {
  width: 72vw;
  border-collapse: separate;
  border-spacing: 0 1vh;
}
.admin-product-table-container th {
  background-color: #e2e6fe;
  padding: 1vw;
}
.admin-product-table-container img {
  width: 2vw;
  color: #fff;
  margin: 0 0.3vw 0 0;
}
.admin-product-table-container td {
  padding: 1vw;
  text-align: center;
  font-weight: 400;
  background-color: #e2e6fe;
}
.admin-product-table-container i {
  margin: 0.5vw;
  text-align: center;
}
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.shimmer {
  min-height: 350px;
  width: 100%;
  border-radius: 8px;
  background: linear-gradient(
      90deg,
      #f0f0f0 25%,
      #e0e0e0 50%,
      #f0f0f0 75%
  );
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
}

.admin-product-header-left {
  display: flex;
  align-items: center;
  gap: 15px;
}

.brand-dropdown-container {
  position: relative;
  display: inline-block;
  width: 200px;
  margin-left: 20px;
}

.brand-dropdown {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: #f9f9f9;
  font-size: 14px;
  color: #333;
  outline: none;
  appearance: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

.brand-dropdown:hover {
  border-color: #007bff;
  background: #f0f8ff;
}

.brand-dropdown:focus {
  border-color: #0056b3;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.brand-dropdown option {
  font-size: 14px;
  color: #333;
}

@keyframes shimmer {
  0% {
      background-position: -200% 0;
  }
  100% {
      background-position: 200% 0;
  }
}
