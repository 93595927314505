.edit-company-container {
  width: 80vw;
  max-width: 40rem; /* Ensures the container doesn't exceed a certain width */
  margin: 0 1rem; /* Centers the container */
  padding: 2rem;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}

.edit-company-container h2 {
  text-align: center;
  margin-bottom: 2rem;
}

.edit-company-container label {
  display: block;
  font-size: 1.1rem;
  color: #333;
  margin: 0.5rem 0;
}

.edit-company-container input {
  width: 100%;
  max-width: 30rem; /* Sets max width for input boxes */
  padding: 0.75rem;
  margin-bottom: 1rem;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 1rem;
}

.edit-company-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}

.edit-company-buttons button {
  width: 45%;
  padding: 0.75rem;
  border-radius: 25px;
  font-size: 1.1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.save {
  background-color: #28a745;
  color: white;
  border: none;
}

.save:hover {
  background-color: #218838;
}

.cancel {
  background-color: #dc3545;
  color: white;
  border: none;
}

.cancel:hover {
  background-color: #c82333;
}

.error {
  color: red;
  font-size: 0.9rem;
}
