.latest-arrival-b2c-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.latest-arrival-b2c-title {
  color: #11314c;
  font-family: sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 4vh 0 4vh 0;
}
.latest-arrival-b2c-card-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0.1vw;
  height: fit-content;
  /* max-width: 20rem; */
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .latest-arrival-b2c-card-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 0.1vw;
    height: fit-content;
  }
}
