.prod-categ-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 1vh 0 1vh 0;
}
.prod-categ-title {
  color: #11314c;
  font-family: sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 4vh 0 4vh 0;
  margin-left: 20px;
}
.prod-categ-card-container {
    padding-left: 100px;
    width: 100%;
    display: flex;
    /* overflow-x: scroll; */
    flex-wrap: wrap;
    gap: 1vh;
    height: fit-content;
    justify-content: center;
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.prod-categ-card-container::-webkit-scrollbar {
  display: none;
}

@media (max-width: 768px) {
  .prod-categ-card-container {
    padding-left: 0px;
    display: flex;
    flex-wrap: wrap;
    gap: 1vh;
    height: fit-content;
    justify-content: center;
  }
}
