.contact-container {
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-wrap: wrap;
  padding: 2rem;
  gap: 2rem;
}

.contact-card-container {
  width: 18vw;
  height: 15rem;
  background-color: #f9f9f9;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: center;
  text-decoration: none;
  color: inherit;
}

.contact-card-container:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.contact-img {
  width: 50px;
  margin-bottom: 1rem;
  padding-top: 1rem;
}

.contact-card-title {
  color: #333;
  font-family: sans-serif;
  font-size: 18px;
  font-weight: 600;
  margin: 0.5rem 0;
}

.contact-card-sub-title {
  color: #777;
  font-family: sans-serif;
  font-size: 16px;
  font-weight: 400;
  margin: 0.5rem 0;
}

.contact-card-link {
  color: #000;
  font-family: sans-serif;
  font-size: 16px;
  font-weight: 500;
  margin-top: 1rem;
  text-decoration: none; /* Remove underline from the contact info */
  cursor: default; /* Ensure it doesn't look like a link */
}

@media (max-width: 768px) {
  .contact-container {
    flex-direction: column;
    padding: 1rem;
  }
  .contact-card-container {
    width: 80vw;
    height: auto;
    margin: 1rem 0;
  }
  .contact-img {
    width: 20vw;
  }
}
