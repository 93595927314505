.product-list-title {
  color: black;
}


.selected-brand {
  font-weight: bold;
  color: white;
  background-color: #eba9d7;
  cursor: pointer;
}

.no-data {
  height:10rem;
  width: 50vw;
  border: 1px solid #ddd;
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}