/* Sort Container */
.sort-container {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  background-color: #f0f0f0;
  border-radius: 8px;
  margin-bottom: 20px;
  justify-content: flex-end;
}

.sort-container label {
  font-size: 16px;
  font-weight: 500;
  color: #333;
}

.sort-container select {
  padding: 8px 12px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  cursor: pointer;
  transition: border-color 0.3s ease;
}

.sort-container select:focus {
  outline: none;
  border-color: #007bff;
}

/* Filter Containers */
.price-filter-container, .slider-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 15px 0;
  padding: 15px;
  background-color: #f8f8f8;
  border-radius: 8px;
  width: 200px;
}

.price-filter-container label, .slider-container label {
  font-size: 14px;
  color: #555;
  display: flex;
  align-items: center;
  gap: 8px;
}

/* Product Card Layout */
.latest-arrival-b2c-container {
  padding: 20px;
  background-color: #ffffff;
  
}

.flex-container{
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 8px;
}

.latest-arrival-b2c-titles {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

.latest-arrival-b2c-card-container {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

/* Product Card Adjustments */
.cat-b2c-card {
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #ffffff;
  transition: box-shadow 0.3s ease;
}

.cat-b2c-card:hover {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.cat-b2c-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  color: #ffffff;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cat-b2c-button:hover {
  background-color: #0056b3;
}

.cat-b2c-title-container {
  margin-top: 10px;
}

.cat-b2c-pro-sub-title {
  font-size: 16px;
  font-weight: 600;
  color: #333;
}

.cat-b2c-pro-title, .cat-b2c-pro-price {
  font-size: 14px;
  color: #777;
}

.filters-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding: 10px 0;
  flex-wrap: wrap; /* Wraps to new lines if there's not enough space */
}

.sort-container,
.price-filter-container,
.slider-container {
  display: flex;
  /* align-items: center; */
}

.price-filter-container label {
  margin-right: 15px; /* Space between checkboxes */
}

.sort-container select,
.slider-container input {
  margin-left: 8px;
}

@media screen and (max-width: 600px) {
  .flex-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
  }
}


.shimmerr {
  min-height: 350px;
  width: 70vw !important;
  border-radius: 8px;
  background: linear-gradient(
      90deg,
      #f0f0f0 25%,
      #e0e0e0 50%,
      #f0f0f0 75%
  );
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
}

@keyframes shimmerr {
  0% {
      background-position: -200% 0;
  }
  100% {
      background-position: 200% 0;
  }
}
