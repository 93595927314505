.testimonial-card-container {
  min-width: 350px;
  flex-shrink: 0;
  height: 350px;
  border-radius: 30px;
  background: #fff;
  /* box-shadow: 0px 6px 10px 2px rgba(0, 0, 0, 0.25); */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.testimonial-card-img {
  width: 100px; /* or any desired width */
  height: 100px; /* or any desired height */
  border-radius: 50%; /* to make it a circle */
  object-fit: cover; /* to maintain aspect ratio */
}
.testimonial-card-sub-title {
  width: 500px;
  color: #000;
  text-align: center;
  font-family: sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin: 1vh;
}
.testimonial-card-title {
  color: #000;
  text-align: center;
  font-family: sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 1vh;
}

@media (max-width: 768px) {
  .testimonial-card-container {
    width: 90vw;
    height: 350px;
    border-radius: 30px;
    background: #fff;
    box-shadow: 0px 6px 10px 2px rgba(0, 0, 0, 0.25);
  }
  .testimonial-card-sub-title {
    width: 70vw;
    color: #000;
    text-align: center;
    font-family: sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin: 1vh;
  }
}

.testimonial-stars {
  display: flex;
  justify-content: center;
  margin-top: 8px;
}

@media (max-width: 500px) {
  .testimonial-card-container {
    width: 100px;
    height: 400px;
    border-radius: 30px;
    background: #fff;
    box-shadow: 0px 6px 10px 2px rgba(0, 0, 0, 0.25);
  }
}
