.admin-logout-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    flex-direction: column;
  }
  
  .admin-logout-container h1 {
    font-size: 24px;
    font-weight: bold;
    color: #333;
  }
  